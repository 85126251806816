.App {
  text-align: center;
  background-color: #1C1C1C;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.App-logo{
  margin-top: 2rem;

  width: 300px;
}
.Icon{
  height: 40px;
}
a{
  text-decoration: none;
}
button{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 300px;
  height: 70px;
  margin-bottom: 10px;
  background-color: #F1F0EA;
  border-radius: 7px;
  border-style: none;
  font-size: 1em;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  color: #302A2A;
  font-weight: 600;
}

footer{
  color: #F1F0EA;
}